<template>
  <div class="arrondi">
    <div class="head">
      <ul>
        <li :class="type == 1 ? 'active' : ''" @click="typeFun(1)">
          周末卷卷堂
        </li>
        <li :class="type == 2 ? 'active' : ''" @click="typeFun(2)">会员优秀作业</li>
      </ul>
    </div>
    <div class="list" v-if="type == 1">
      <ul>
        <li @click="goDetail(item.id)" v-for="(item, index) of list" :key="index">
          <div class="thumb">
            <div class="img">
              <img :src="item.thumb" />
            </div>
          </div>
          <div class="bottom">
            <span>{{ item.start_time }}—{{ item.end_time }}</span>
            <div class="title">【周末卷卷堂】{{ item.title }}</div>
            <div :class="item.activity_status==1?'countdown':'countdown countdown2'">{{ item.activity_time }}</div>
          </div>
        </li>
      </ul>
       <el-empty
          v-if="list_total < 1"
          description="暂无数据"
        ></el-empty>
        <div class="page" v-if="list_total > 8">
          <page
          :page="page"
          :limit="limit"
          :total="list_total"
          ref="page"
        ></page>
        </div>
        
    </div>
    <div v-if="type == 2">
    <div class="list2 video_list video_lists clearfix">
   
      <div class="daily_ul" v-for="(row,i) of good_list" :key="i">
        <!-- :style="{ animationDelay: 0.1 * index + 's' }" -->
        <div class="video_li" v-for="(item,index) of row" :key="index">
          <div class="video_li_box" @click="toVideoDetail(item.video_id,item.id)">
            <img v-lazy="item.thumb" alt="" />
            <!-- <div class="video_li_mask"></div>
            <div class="video_li_play">
              <img src="../assets/images/icon_play@2x.png" alt="" />
            </div> $parent.getUser(item.user.id)-->
          </div>
          <div class="clearfix pad15">
            <div class="fl dailyWord_li_l" @click.stop="goodsTaskFun(item.user.id)">
              <img class="fl" :src="item.user.thumb" alt="" />
              <span>{{item.user.username}}</span>
            </div>
            <div class="fr dailyWord_li_r">
              <div :class="item.is_like==1?'zan zanActive':'zan'" @click.stop="good(item.id)">
                <img src="../assets/images/zan_new.svg" v-if="item.is_like==1" />
                <img src="../assets/images/zan_new2.svg" v-else />
                <span>{{item.goods}}</span>
              </div>
            </div>
          </div>
          <div class="video_li_reading clearfix pad15">
            <div class="fl">
              <div class="video_li_name">视频：<a @click="toVideo(item.video_id)">{{item.video_name}}</a></div>
            </div>
            <div class="fr clearfix">
              <div class="fl clearfix video_li_reading_k">
                <span class="fl">{{item.last_time}}</span>
              </div>
            </div>
          </div>
        </div>
      
      </div>
      
    </div>
     <el-empty
          v-if="good_list_total < 1"
          description="暂无数据"
        ></el-empty>
        
        <div class="page2" v-if="good_list_total > 8">
          <page
          :page="page"
          :limit="limit"
          :total="good_list_total"
          ref="page"
        ></page>
    </div>
    </div>
  </div>
</template>

<script>
import page from "../components/page.vue";
export default {
  name: "arrondi",
  data() {
    return {
      list: [],
      list_total: 0,
      good_list: [],
      good_list_total: 0,
      type: 1,
      page: 1,
      limit: 8,
      headers: {
        token: localStorage.getItem("access_token"),
      },
    };
  },
  components: {
    page,
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.type == 1) {
      this.limit=8;
      this.getList();
    } else {
      this.limit=40;
      this.getGoodList();
    }

    this.$parent.routerIndex = 8;
    this.$parent.showIndex = 1;
    this.$parent.shadow = false;
  },
  methods: {
    //点赞
    good(id) {
      if (!this.headers.token) {
        this.$parent.loginShow();
        return;
      }

      var that = this;
      var params = {
        task_id: id,
      };
      this.$api.POST(this.$face.good, params, function (res) {
        if (res.code == 200) {
          for (let i in that.good_list) {
            for (let i2 of that.good_list[i]) {
              if (i2.id == id) {
                if (res.msg == "取消成功") {
                  i2.is_like = 0;
                  i2.goods = i2.goods - 1;
                } else {
                  i2.is_like = 1;
                  i2.goods = i2.goods + 1;
                }
              }
            }
          }
        }
      });
    },
    listFun(){
      if(this.type==1){
        this.getList();
      }else{
        this.getGoodList();
      }

    },
    getList() {
      let this_ = this;
      this.$api.POST(
        this.$face.video_activity_list,
        {
          page: this.page,
          limit: this.limit,
        },
        function (res) {
          if (res.code == 200) {
            this_.list = res.data.list;

            this_.list_total = res.data.total;
          }
        }
      );
    },
    getGoodList() {
      let this_ = this;
      this.$api.POST(
        this.$face.video_activity_good,
        {
          page: this.page,
          limit: this.limit,
        },
        function (res) {
          if (res.code == 200) {
            let lists = res.data.list;
            console.log(lists);
            for (let i in lists) {
              if (lists[i].user.thumb.indexOf("http") == -1) {
                lists[i].user.thumb = require("../assets/images/head.png");
              }
            }

            this_.good_list = this_.$utile.listData(lists);

            this_.good_list_total = res.data.task_total;
          }
        }
      );
    },
    typeFun(type) {
      this.type = type;
      this.page = 1;
      if (type == 1) {
        this.limit=8;
        this.getList();
      } else {
        this.limit=40;
        this.getGoodList();
      }
    },
    toVideoDetail(id,task_id){
      let routeData = this.$router.resolve({
        name: "videoDetail",
        query: {
          id: id,
          task_id:task_id
        },
      });
      window.open(routeData.href, "_blank");
    },
    toVideo(id){
      let routeData = this.$router.resolve({
        name: "videoDetail",
        query: {
          id: id
        },
      });
      window.open(routeData.href, "_blank");
    },
    goodsTaskFun(id){
      let routeData = this.$router.resolve({
        name: "userWork",
        query: {
          userId: id,
          type: 2,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goDetail(id) {
      let routeData = this.$router.resolve({
        name: "arrondiDetail",
        query: {
          type: this.type,
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {
    "$route.query.type"(newval) {
      this.typeFun(this.$route.query.type);
    },
    "$store.state.userInfo"(newval) {
      this.headers.token = localStorage.getItem("access_token");
    },
  },
  activated() {
    this.$nextTick(() => {
      this.$parent.routerIndex = 8;
    });
  },
};
</script>

<style scoped>
.arrondi {
  border-top: 1px #ccc solid;
}
.head {
  height: 57px;
  background: #fff;
}
.head ul {
  max-width: 1285px;
  margin: auto;
}
.head li {
  float: left;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: #555555;
  cursor: pointer;
}
.head li:first-child {
  margin-right: 75px;
}
.head li.active {
  border-bottom: 2px #555555 solid;
}
.list {
  width: 1290px;
  margin: 20px auto;
  overflow: hidden;
}
.list ul {
  overflow: hidden;
}
.page {
  clear: both;
  margin-top: 20px;
}
.list li {
  width: 634px;
  height: 445px;
  border-radius: 15px;
  background: #fff;
  overflow: hidden;
  float: left;
  margin-right: 21px;
  margin-bottom: 21px;
  cursor: pointer;
}
.list li:nth-child(2n) {
  margin-right: 0;
}
.list li .thumb {
  height: 357px;
  overflow: hidden;
  position: relative;
}
.list li .thumb .img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 356px;
}
.list li .thumb img {
  width: 100%;
  transition: 0.5s;
}
.list li:hover img {
  transform: scale(1.05);
}
.bottom {
  position: relative;
  clear: both;
  overflow: hidden;
  padding-top: 18px;
}
.bottom span {
  font-size: 12px;
  color: #939393;
  padding-left: 15px;
}
.bottom .title {
  font-size: 18px;
  padding-left: 5px;
  margin-top: 11px;
}
.countdown {
  position: absolute;
  right: 0;
  top: 23px;
  height: 32px;
  background: #ff0303;
  padding: 0 21px 0 21px;
  line-height: 32px;
  color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  font-size: 15px;
}
.countdown2 {
  background: #bfbfbf;
}

.list2 {
  width: 1315px;
  margin: 20px auto;
}
/* 视频教程 */
.video_list {
  position: relative;
  z-index: 9;
  background-color: #f6f6f6;
  min-height: 200px;
}

.video_lists {
  display: flex;
}

.daily_ul {
  margin: 0 13.33px;
  width: calc(100% / 4);
}

.video_li {
  width: 305px;
  margin: 0 0 24px;
  background: #fff;
  border-radius: 10px;
  /* padding: 15px; */
  padding: 0 0 15px 0;
  box-sizing: border-box;
  overflow: hidden;
}

.video_lis {
}

.video_li_box {
  width: 100%;
  /* height: 198px; */
  /* border-radius: 10px; */
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.video_li_box img {
  width: 100%;
}

.video_li_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  z-index: 33;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.video_li_play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 101;
  margin-left: -37.5px;
  margin-top: -37px;
  width: 74px;
  height: 75px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0;
}

.video_li:hover .video_li_mask {
  opacity: 1;
}

.video_li:hover .video_li_play {
  opacity: 1;
}

.video_li_name {
  /* height: 52px;
    line-height: 49px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #565656;
  width: 210px;
  overflow: hidden;
  /* 第二步：让文本不会换行， 在同一行继续 */
  white-space: nowrap;
  /* 第三步：用省略号来代表未显示完的文本 */
  text-overflow: ellipsis;
}
.video_li_name a{ color: #555; cursor: pointer;}

.video_li_reading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
  margin-top: 10px;
}

.video_li_reading img {
  width: auto;
  height: 20px;
  margin-right: 4px;
}

.video_li_reading span {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
}

.video_li_reading_k {
}
.dailyWord_li_l {
  line-height: 24px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  margin-top: 15px;
}
.dailyWord_li_l img {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: solid 1px #f6f6f6;
  box-sizing: border-box;
  border-radius: 36px;
  margin-right: 8px;
}
.dailyWord_li_r {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #999999;
  margin-top: 15px;
}
.daily_li:hover .ranking_li_box img {
  transform: scale(1.08);
}
.zan {
  line-height: 16px;
  font-size: 13px;
  cursor: pointer;
}
.zan img {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.pad15 {
  padding: 0 15px;
}
.zanActive {
  color: #4342ff;
}
.page2{padding: 20px 0 80px;}
</style>